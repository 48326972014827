
// 获取我的签到列表数据
const getCheckInList = `/gateway/hc-serve/miniapi/activity/myActivityList`;
//获取签到详情
const getActivityDetail = `/gateway/hc-serve/miniapi/activity/myActivityDetail`;  
//签到
const signInOrOut = `/gateway/hc-serve/miniapi/activity/signInOrOutByUserId`
//代签到
const allographUrl = `/gateway/hc-serve/manageapi/activity/allograph`;
export {
    getCheckInList,
    getActivityDetail,
    signInOrOut,
    allographUrl
  };